<template>
    <v-card :color="color" :loading="loading" :outlined="outlined" :width="width" :height="height" rounded="md" :link="link">
        <v-img :lazy-src="src" contain max-height="500" />

        <v-card-text>
            <slot name="header" />
        </v-card-text>

        <v-card-title>{{ title }}</v-card-title>

        <v-card-actions>
            <slot name="footer" />
        </v-card-actions>
    </v-card>
</template>


<script lang="ts">
export default {
    name: "DisplayCard",
    props: {
        loading: {
            type: Boolean,
            default: false
        },


        link: {
            type: String
        },

        color: {
            type: String
        },

        width: {
            type: Number,
        },

        height: {
            type: Number
        },

        title: {
            type: String,
        },

        src: {
            type: String,
        },

        outlined: {
            type: String
        }
    }
}
</script>