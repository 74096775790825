<template>
    <div id="burger" aria-label="burger" :class="{ 'active': collapse }" @click.prevent="toggle">
        <slot>
            <button type="button" class="burger-button" title="Menu">
                <span class="hidden">Toggle menu</span>
                <span class="burger-bar burger-bar--1"></span>
                <span class="burger-bar burger-bar--2"></span>
                <span class="burger-bar burger-bar--3"></span>
            </button>
        </slot>
    </div>
</template>


<script lang="ts">


import { useAppStore } from "@/store/modules/appStore";
import { mapStores } from "pinia";
import Vue, { ref, unref } from "vue";
import Component from "vue-class-component";



@Component({
    components: {},
    computed: {
        collapse: function() {
            return this.store.getCollapse; 
        },

        ...mapStores(useAppStore, {
            collapse: 'collapse'
        })
    }
    
})



export default class Burger extends Vue {
   store: any = useAppStore(); 
    toggle() {
        const collapsed = unref(false); 
        this.store.setCollapse(!collapsed); 
    
    }
}
</script>


<style lang="css" >
.hidden {
    visibility: hidden;
}


button {
    cursor: pointer;
}


button:focus {
    outline: 0;
}


.burger-button {
    position: relative;
    height: 50px;
    width: 32px;
    display: block;
    z-index: 999;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    pointer-events: all;
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    /* Add transition here */
}



.burger-bar {
    background-color: black;
    position: absolute;
    top: 56%;
    right: 6px;
    left: 6px;
    height: 2px;
    width: auto;
    opacity: 0.3s cubic-beizer(0.165, 0.84, 0.44, 1);
    background-color: 0.6s cubic-beizer(0.165, 0.84, 0.44 1);
    margin-top: -1px;
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

}

.burger-bar--1 {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
}


.burger-bar--2 {
    transform-origin: 100% 50%;
    transform: scaleX(0.8);
}



.burger-bar--3 {
    transform: translateY(6px);
}


#burger.active .burger-butto {
    transform: rotate(-180deg);
}


#burger.active .burger-bar {
    background-color: black;
}


#burger.active .burger-bar--1 {
    transform: rotate(45deg);
}


#burger.active .burger-bar--2 {
    opacity: 0;
}


#burger.active .burger-bar--3 {
    transform: rotate(-45deg);
}

.burger-button:hover .burger-bar--2 {
    transform: scaleX(1);
}

.no-touchevents .burger-bar--2:hover {
    transform: scaleX(1);
}
</style>