import {IResponse} from "@/global";
import {useAxios} from "../axios/useAxios";
import {AxiosResponse} from "axios";


const request = useAxios();


export const saveCategoryApi = async (data: string): Promise<IResponse> => {
    const res = await request.post({
        url: '/api/user-category/create',
        data
    })

    return res && res.data;
}



export const updateCategoryApi = async (id: string, data: any): Promise<IResponse> => {
    const res = await request.put({
        url: `/api/user-category/update/${id}`,
        data: data
    })


    return res && res.data;
}



export const getAllCategoriesApi = async (): Promise<AxiosResponse> => {
  return await request.get({
      url: '/api/user-category/all'
    });
}



export const delCategoryApi = async (id: string | number): Promise<IResponse> => {
    const res = await request.delete({
        url: '/api/user-category/delete',
        params: { id }
    });

    return res && res.data;
}


