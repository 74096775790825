

export interface AppState {
    currentUser: any;
    collapse: boolean; 
}



/**
 * @description application module here
 * @returns an Object
 */
export const appModule: AppState = {
    currentUser: null,
    collapse: false,
}
