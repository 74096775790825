import Vue from "vue";
import Vuetify from "vuetify/lib/framework";


// @ts-ignore
const Plugin = new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        // primary: '#11A1FD',
        // secondary: '#11A1FD',
        primary: '#14C38E',
        secondary: "#76BF05",
        orange: "#FF5733",
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#5A75F8',
        success: '#07C180',
        warning: '#FF9931',
        black: "#223345",
        drawerBg: "#016b4b",
        basic: "#7D9EB5",
        drawerLinkVisited: "#CAE1EE",
        basicLight: '#b2c1ce'
      },
    },
  },

    icons: {

    }
});


export default Plugin;
