
const Storage = {
    getCookie: function (cname: string) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },

    setCookie: function (cname: string, cvalue: string, exhours: number) {
        const d = new Date();
        d.setTime(d.getTime() + (exhours * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },


    setAccessToken: function (val: string) {
        return this.setCookie("ctoken", val, 9)
    },

    getAccessToken: function () {
        return this.getCookie("ctoken")
    },

    resetAccessToken: function () {
        return this.setCookie("ctoken", "", 0)
    },

    getRefreshToken: function () {
        return this.getCookie("refresh")
    },

    setRefreshToken: function (val: string) {
        return this.setCookie("refresh", val, 3)
    },

    resetRefreshToken: function () {
        return this.setCookie("refresh", "", 0)
    },
};

export default Storage;
