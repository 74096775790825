0<template>
    <div class="sidebar" v-if="collapse">
        <div class="sidebar-backdrop" @click="closePanel" v-if="!collapse"></div>
        <transition name="slide">
            <div class="sidebar-panel">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>


<script lang="ts">
import Vue from "vue";
import { mapState } from "pinia"; 
import { useAppStore } from "@/store/modules/appStore";
import Component from "vue-class-component";


@Component({
    components: {}, 


    computed: {
        ...mapState(useAppStore, ['collapse'])
    }
})


export default class Sidebar extends Vue {
    // public store: any = useAppStore(); 

    store: any = useAppStore(); 

    closePanel() {
       return this.store.getCollapse; 
    }


    created() {
       // this.store.collapse = false
    }
}

</script>


<style lang="css">
.slide-enter-active {
    transition: all 0.3s ease-out;
}

.slide-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); 
}


.slide-enter-from, .slide-leave-to {
    transform: translateX(20px); 
    opacity: 0; 
}


.sidebar-backdrop {
    background-color: rgba(19, 15, 64, .4);
    width: 100vw;
    height: max-content;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
}

.sidebar-panel {
    overflow-y: auto;
    position: fixed; 
    background-color: white;
    left: 0;
    height: 100vh;
    z-index: 999;
    padding: 3rem 20px 2rem 20px;
    width: 250px;
}
</style>