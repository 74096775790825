

<template>
    <p class="para">{{ title }}</p>
</template>


<script lang="ts">

export default {
    name: "Text", 
    props: {
        title: {
            type: String, 
            default: 'Hello World'
        }
    }
}
</script>


<style lang="css" scoped>
.para {
    line-height: 1.5em; 
}
</style>