export default {
    emailRules: [
        (v: any) => !!v || 'This field is required',
        (v: string) => /.+@.+([.])+.+/.test(v) || 'E-mail must be valid'
    ],

    textRules: [(v: any) => !!v || 'This field is required'],

    numericRules: [
        (v: string) => /^[0123456789]+$/.test(v)  || 'Invalid data'
    ],

    nonZeroNumbers: [
        (v: string) => /^[123456789]+$/.test(v)  || 'Invalid data'
    ],

    decimalsRules: [
        (v: any) => !!v || 'This field is required',
        (v: string) => /^\d+(\.\d{0,8})?$/.test(v)  || 'This field must be numeric'
    ],

    passwordRules: [
        (v: any) => !!v || 'Password is required',
        (v: string | any[]) => (v && v.length >= 8) || 'Password must have at least 8 characters',
        // v => /(?=.*[A-Z])/.test(v) || 'Must have one uppercase character',
        // v => /(?=.*\d)/.test(v) || 'Must have one number',
        // v => /([!@$%])/.test(v) || 'Must have one special character [!@#$%]'
    ]
}
