import AuthUtil from "@/hooks/useCache";

export default {
    async checkErrors(viewInstance: any, error: any) {
        viewInstance.messageType = 'error'
        // console.log(error)
        if (error.response) {
            //user is not signed in
            if (error.response.status === 401) {
                if (error.config.url.includes('/login')) {
                    viewInstance.message = 'Invalid credentials'
                } else if (error.config.url === '/refresh-token') {
                    window.location.reload()
                } else {
                    viewInstance.message = 'Signin required'
                    AuthUtil.resetRefreshToken()
                    AuthUtil.resetAccessToken()
                }

            } else if (error.response.status === 403) { // unauthorized access
                viewInstance.message = 'Access denied'
            }
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            else if (error.response.data.error) {
                viewInstance.message = error.response.data.error
            } else if (error.response.data.message) {
                viewInstance.message = error.response.data.message
            } else {
                // check if its a blob
                if (error.response.data instanceof Blob) {
                    // convert to blob
                    this.toJson(viewInstance, error.response.data)
                    return
                }
                else
                    viewInstance.message = error.response.data;
            }

            viewInstance.messageShow = true
            // viewInstance.message = "An error has occurred";
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            viewInstance.message = "Error! no response from server"
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            viewInstance.message = error.message;
        }

      viewInstance.messageShow = true;
    },

    async toJson(vueInstance: any, err: any) {
        // eslint
        const r = await err.text()
        const parsed = JSON.parse(r);
        // check
        if (parsed.error) {
            vueInstance.message = parsed.error
        } else {
            vueInstance.message = parsed.message
        }

        vueInstance.messageType = 'error'
        vueInstance.messageShow = true
    }
}

