<template>
  <v-dialog persistent overlay-opacity="0.1" content-class="rounded-xl" v-model="$props.show" max-width="500"
            class="mx-auto" transition="dialog-bottom-transition" style="">
    <v-card elevation="1" rounded="xl">
      <v-card-title class="pt-2 ">
        <div class="font-weight-bold mx-auto" :class="{isMedium: 'mx-0'}">
          {{ $props.title }}
        </div>

        <slot name="title"/>
        <v-spacer/>
        <!--    close icon    -->
        <v-icon size="24" color="black" @click="closeGuide" class="py-1 px-1 my-4">mdi-close</v-icon>

      </v-card-title>

      <v-card-text class="mt-4">
        <slot name="header"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';


@Component({
  props: {
    show: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String
    }
  },

  computed: {
    isMedium: function () {
      return this.$vuetify.breakpoint.mdAndUp;
    }
  }
})


export default class Guide extends Vue {

  public closeGuide() {
    this.$emit('close');
  }
}
</script>
