<template>
  <v-row md="1">
    <Guide :show="$props.show" title="Sign in" @close="$emit('dismiss')">
      <template v-slot:header>
        <v-row>
          <v-col md="4" :align="isMobile ? 'center' : 'start'">
            <v-img aspect-ratio="1" :width="!isMobile ? 300 : 150" :height="!isMobile ? 200 : 100" contain
                   src="/img/login.svg"/>
          </v-col>


          <v-col cols="12" md="8" sm="12">
            <v-window v-model="step">
              <div class="d-block">
                <v-form ref="form" v-model="isValid" class="d-block">
                  <v-window-item :value="1">
                    <v-text-field :rules="rules.textRules" full-width color="black" dense
                                  v-model="formData.username" placeholder="StaffID"/>
                    <v-text-field type="password" :rules="rules.passwordRules" full-width color="black"
                                  @keyup.enter="signin" dense v-model="formData.password" placeholder="Password"/>
                  </v-window-item>

                  <v-window-item :value="2">
                    <v-text-field full-width color="black" dense placeholder="Biometric"/>
                  </v-window-item>

                  <div class="mt-5">
                    <v-btn @click="signin" color="primary" width="100%" rounded depressed>
                      Sign in
                    </v-btn>
                  </div>
                </v-form>
              </div>
            </v-window>
          </v-col>

          <v-col cols="12" md="8" sm="6" class="mx-auto">
            <div class="d-block text-center text-md-right">
              <p class="" style="font-size: 14px; color: black; line-height: 1.2em;">Don't have an account?</p>
              <p style="color: #76BF05; line-height: 0.8em" class="mr-md-10"><a
                href="mailto:support@nsuk.edu.ng">Support</a></p>
            </div>
          </v-col>

<!--          <v-col cols="6" md="4" sm="6" class="mx-auto">-->
<!--            <div class="d-block text-center">-->
<!--              <p class="" style="font-size: 14px; color: black; line-height: 1.2em;">Having troubles?</p>-->
<!--              <p class="" @click="step++" style="color: #14C38E; line-height: 0.8em; cursor: pointer;" href="#">-->
<!--                Switch Mode-->
<!--              </p>-->
<!--            </div>-->
<!--          </v-col>-->
        </v-row>
      </template>
    </Guide>
    <loading-dialog :show="loading" />
    <message-box :show="messageShow" :message="message" :type="messageType"
                 v-on:close-message="messageShow = !messageShow" />
  </v-row>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Guide from "@/components/Guide.vue";
import ValidationRules from "@/utils/ValidationRules";
import {loginApi} from "@/config/api/login";
import Storage from "@/hooks/useCache";
import ErrorResolver from "@/utils/ErrorResolver";
import LoadingDialog from "@/components/LoadingDialog.vue";
import MessageBox from "@/components/MessageBox.vue";
import { UserLoginType } from "@/types/login";

@Component({
  components: {MessageBox, LoadingDialog, Guide},
  props: {
    show: Boolean
  }
})
export default class Login extends Vue {

  public step: number = 1;
  public isValid: boolean = false;
  public rules = ValidationRules;
  public loading: boolean = false;
  public items: any = []
  public message: string = '';
  public messageShow: boolean = false;
  public messageType: string = '';
  public dialogVisible: boolean = false;
  public formData: UserLoginType = {
    username: '',
    password: ''
  }

  public close() {
    this.$emit('dismiss')
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  public signin() {
    this.loading = true;
    loginApi(this.formData).then((res) => {
      // this.dialogVisible = false;
      Storage.setAccessToken(res.data.jwt);
      window.location.href = '/dashboard';
    }).catch(err => {
      console.log(err)
      ErrorResolver.checkErrors(this, err)
    })
      .finally(() => {
        this.loading = false;
      });
  }
}
</script>

<style scoped>

</style>
