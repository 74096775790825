const RoleUtils = {
  ADMIN: 'ADMIN',
  SUPER_ADMIN: 'SUPER_ADMIN',
  STAFF: 'STAFF',
  BIOMETRIC_OFFICER: 'BIOMETRIC_OFFICER',

  contains: (roles: any, role: any) => {
    return roles.includes(role)
  },

  deepContains: (mRoles: any, uRoles: any) => {
    for (let i = 0; i < uRoles.length; i++) {
      for (let j = 0; j < mRoles.length; j++){
        if(uRoles[i] === mRoles[j])
          return true
      }
    }
    return false
  }
}

export default RoleUtils
