
<template>
  <v-dialog 
    v-model="$props.show" 
    overlay-opacity="0.1" 
    transition="dialog-bottom-transition"
    :persistent="$props.persistent" 
    max-width="480" 
    :fullscreen="$props.fullscreen"
  >
    <v-card outlined tile elevation="0">
      <v-card-title class="pt-2">
        <div>
          {{ $props.title }}
        </div>

        <slot name="title" />
        <v-spacer />

<!--        <v-btn icon @click="close">-->
          <v-icon size="24" color="secondary" @click="close">mdi-close</v-icon>
<!--        </v-btn>-->
      </v-card-title>

      <v-card-text class="mt-4">
        <slot name="header" />
      </v-card-text>

      <v-card-actions>
        <slot name="footer" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">

import Vue from "vue";
import Component from "vue-class-component";

@Component({
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
    },
    section: {

    },
    opacity: {
      type: String,

    },
    sectionActions: {
      type: String,
      default: 'Dialog'
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    fullscreen: {
      type: Boolean
    }
  }
})



export default class Dialog extends Vue {
  public close(){
   // this.$emit('close-dialog')
    this.$emit('dismiss')
  }
}

</script>


