import Vue from "vue";
import VueToast from "vue-toast-notification";
import 'vue-toast-notification/dist/theme-sugar.css';
import Vuetify from "vuetify";
import router from "./router";
import Plugin from "@/plugins/vuetify";
import { PiniaVuePlugin } from "pinia";
import { store } from "@/store";
import App from "./views/App.vue";

Vue.use(Vuetify);
Vue.use(PiniaVuePlugin);
Vue.use(VueToast);


Vue.config.productionTip = false;


new Vue({
    router,
    pinia: store,
    vuetify: Plugin,
    render: h => h(App)
}).$mount('#app');
