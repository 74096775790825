import {appModule, AppState} from "@/config/app";
import {defineStore, } from "pinia";
import RoleUtils from "@/utils/roleUtils";




export const useAppStore = defineStore("app", {
  state: (): AppState => appModule,
  // getters
  getters: {
    getCurrentUser: (state) => {
      return state.currentUser
    }, 

    getCollapse(): boolean {
      return this.collapse
    }
  },

  // actions
  actions: {
    // update current user
    setCurrentUser(user: any): void {
      this.currentUser = user
    },

    isAdmin(): boolean {
      return RoleUtils.deepContains(this.currentUser?.roles, [RoleUtils.ADMIN, RoleUtils.SUPER_ADMIN])
    },

    isStaff(): boolean {
      return RoleUtils.deepContains(this.currentUser?.roles, [RoleUtils.STAFF, RoleUtils.SUPER_ADMIN])
    },

    isBiometricOfficer(): boolean {
      return RoleUtils.deepContains(this.currentUser?.roles, [RoleUtils.BIOMETRIC_OFFICER, RoleUtils.SUPER_ADMIN])
    },

    isSuperAdmin(): boolean {
      return RoleUtils.deepContains(this.currentUser?.roles, [RoleUtils.SUPER_ADMIN])
    }, 


    setCollapse(collapse: boolean): boolean {
      return this.collapse = collapse; 
    }
  }
})
