<template>
    <div class="text-center">
      <v-snackbar
          v-model="visible"
          :multi-line="false"
          timeout="15000"
          :color="type"
          top
          right
          transition="slide-x-transition"
      >
        {{ '' }}

        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="close"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </template>

  <script lang="ts">
  export default {
    name: "MessageBox",
    props: {
      show: Boolean,
      message: String,
      type: String, // success, warning or error
    },

    data: () => ({
      visible: false
    }),

    watch: {
      show: function(newVal){
        if(newVal){
          this.$toast.open({
            message: this.message,
            type: this.type,
            position: 'top-right'
          });

          this.close()
        }
      }
    },

    methods: {
      close(){
        // this.$props.show = false;
        this.$emit('close-message');
      }
    },

    computed: {
      icon: function(){
        return (this.type==='success')?'check-circle':(this.type === 'error' ? 'alert-circle' : (this.type === 'info' ? "information" : 'alert'))
      }
    }
  }
  </script>

  <style scoped>

  </style>
