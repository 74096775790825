import Vue from 'vue'
import VueRouter, { RouteConfig, } from 'vue-router';
import HomePage from "../views/index.vue"

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/home',
        name: 'Home',
        component: HomePage
    },

    {
        path: '/home/staffs/:category',
        name: 'Staffs',
        component: () => import("../views/Profiles.vue")
    },

    {
        path: '/home/profile',
        name: 'Verify',
        component: () => import("../views/Verification.vue")
    },

    {
        path: '*',
        name: 'NotFound',
        component: () => import("@/components/Missing.vue")
    }
];

const router = new VueRouter({
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact-active',
    mode: "history",
    routes,
});

export default router
