<template>
  <!-- HOMEPAGE TO THE ERP-->
  <div>
    <header class="header">
      <div style="display: flex; background-color: transparent; align-items: center; gap: 1rem;">
        <v-img  :width="adjustImg" src="/img/logo.png" alt="nsuk" contain />
        <h2>Nasarawa State University, Keffi</h2>
      </div>


      <!---->
      <div class="right">
        <ul>
          <li v-for="(item, index) in tabs">

          </li>
        </ul>
      </div>

    </header>


    <!-- Layout -->

    <!-- Staff Profile-->
    <div v-if="selectedTab.name === 'Staff Profile'">

    <!--------------- HERO SECTION --------------------------------->
    <div class="overlay">
      <div class="mx-6 mx-md-16 mx-sm-16 hero" :style="{ paddingLeft: padLeft }">
        <v-row :justify="isMobile ? 'center' : 'space-between'" class="herocontent">
          <v-col cols="12" md="8" sm="12" :style="{ marginTop: checkmTop }">
            <h3 class="font-weight-bold hFont text-center text-md-left text-sm-left" style="line-height: 1.2em; ">
              Welcome
              to NSUK Staff
              Profile
            </h3>
            <p class="font-weight-medium pFont mt-2 text-center text-md-left text-sm-left"
              style=" line-height: 1.4em; color: white;">
              Review the profiles of 2000+ staff quickly
              and easily.
            </p>
          </v-col>
        </v-row>


        <!------    NON-MOBILE   ------>
        <div class="d-md-flex mt-10 mb-8" v-if="isMedium">
          <v-btn color="primary" :width="smWidth" x-large rounded depressed @click="showDialog">
            Sign in
          </v-btn>

          <v-btn class="ml-md-8 ml-0 support" :width="smWidth" outlined x-large depressed rounded>
            Support
          </v-btn>


        </div>


        <!-- IPAD SCREEN FOR PORTRAIT-->
        <div class="d-md-flex mt-10 mb-8" v-else-if="$vuetify.breakpoint.smOnly">
          <v-btn color="primary" :width="smWidth" x-large rounded depressed @click="showDialog">
            Sign in
          </v-btn>

          <v-btn class="ml-md-8 ml-0 ml-sm-5 support" :width="smWidth" outlined x-large depressed rounded>
            Support
          </v-btn>
        </div>



        <!--------   MOBILE   -------------->
        <div class="d-block d-sm-flex mt-10 mb-8 " v-else>
          <div class="text-center">
            <v-btn x-large width="70%" color="primary" rounded depressed @click="showDialog">
              Sign in
            </v-btn>
          </div>

          <div class="text-center mt-4 mt-sm-0">
            <v-btn class="support" width="70%" outlined x-large depressed rounded>
              Support
            </v-btn>
          </div>
        </div>



        <!------------- BOUNCING-BUTTON ------------>
        <v-btn fab depressed class="mt-16 mt-md-10 mt-sm-0 btn" @click="gotBottom" v-if="isMedium">
          <v-icon color="primary">mdi-arrow-down</v-icon>
        </v-btn>
      </div>
    </div>



    <!---------------- CONTENT SECTION --------------------------->
    <div class="mx-10 mx-md-16" :style="{ paddingLeft: padLeft }">
      <div>
        <v-row justify="space-between" class="mt-10">
          <v-col cols="12" md="4" sm="6" v-for="(item, index) in items" :key="index * 100">
            <v-card rounded="xl" class="py-4" :width="checkCard" style="border: 2px solid #76BF05;">
              <v-card-text class="mx-2">
                <v-row>
                  <v-col class="d-flex">
                    <v-img class="md-mr-5" src="/img/ceritificate.svg" width="50" contain aspect-ratio="1"
                      height="auto" />
                    <div class="d-block">
                      <v-card-title class="font-weight-bold text-h5" style="color: black; line-height: 1.3em;">
                        {{ item.name }}</v-card-title>
                      <p class="mx-3" style="color: black; line-height: 1.3em; font-size: 16px;">Browse through the list
                        of
                        our {{ item.name }} staffs.</p>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="mx-5">
                <v-btn :href="'/home/staffs/' + item.id" depressed color="primary" rounded height="40px" width="100%">
                  View</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

      </div>


      <div style="padding-top: 100px; ">
        <v-row justify="space-between">
          <v-col cols="12" md="3" sm="12">
            <h3 class="font-weight-bold text-center text-md-left" style="line-height: 1.0em; padding-bottom: 100px;"
              :style="{ fontSize: checkFont }">
              Our staff and numbers say a lot about us</h3>
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <v-card link style="cursor:pointer" outlined rounded width="400" elevation="5" class="shadow py-10 px-5 rounded-xl">
              <v-card-title class="font-weight-bold text-center text-md-left" style="color:#14C38E; font-size: 50px">
                <v-spacer v-if="isMobile"></v-spacer>
                <div class="text-center text-md-left">100+</div>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text class="font-weight-bold" style="color: black;">
                <p class="text-center text-md-left pt-3" style="font-size: 18px; line-height: 1.3em;">
                  Our students are guided by academic experts, who have an impact on knowledge at all levels.
                </p>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <v-card link style="cursor:pointer" outlined elevation="5" class="shadow py-10 px-5 rounded-xl" width="400">
              <v-card-title class="font-weight-bold text-center text-md-left" :class="{ isMedium: 'mx-0' }"
                style="color: #14C38E; font-size: 50px; ">
                <v-spacer v-if="isMobile"></v-spacer>
                <div class="text-center text-md-left">120+</div>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text class="font-weight-bold" style="color: black;">
                <p class="text-center text-md-left pt-3" style="font-size: 18px; line-height: 1.3em; ">
                  Non-academic staffs
                  coordinating school and
                  administrative activities
                  round the clock.
                </p>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>


      <div class="mt-4 py-40">
        <v-row justify="space-between">
          <v-col cols="12" md="4" sm="12" style="padding-bottom: 120px;">
            <h3
              class="font-weight-bold text-center text-md-left" style="color: black; font-size: 3rem; line-height: 1.1em;"
              :style="{ fontSize: checkFont }"
            >
              Learn
              about<br /> what our
              staff do
            </h3>
          </v-col>

          <v-col cols="12" md="6" sm="12">
            <div class="d-md-flex d-block text-center text-md-left">
              <v-btn fab color="primary" depressed>
                1
              </v-btn>


              <div class="mx-md-5 mx-0">
                <p class="font-weight-bold" style="color: black; font-size: 26px;">Connect with our staff via socials
                </p>
                <p style="font-size: 20px;">
                  Find social media handles of our staff
                  and connect with them.
                </p>
              </div>
            </div>
          </v-col>


          <v-col cols="12" md="6" sm="12">
            <div class="d-md-flex d-block text-center text-md-left">
              <v-btn color="primary" fab depressed>
                2
              </v-btn>

              <div class="mx-md-5 mx-0">
                <p class="font-weight-bold" style="color: black; font-size: 26px;">Find any staff working in the
                  institution</p>
                <p style="font-size: 20px;">An up to date list of all staffs working in
                  Nasarawa State University.</p>
              </div>
            </div>
          </v-col>


          <v-col cols="12" md="6" sm="12">
            <div class="d-md-flex d-block text-center text-md-left">
              <v-btn color="primary" fab depressed>
                3
              </v-btn>

              <div class="mx-md-5 mx-0">
                <p class="font-weight-bold" style="color: black; font-size: 26px;">Up-to-date</p>
                <p style="font-size: 20px;">The list is updated in real time based on
                  active status of staffs in the university.</p>
              </div>
            </div>
          </v-col>
        </v-row>

      </div>



      <div class="d-block py-40">
        <div class="">
          <v-btn class="d-flex mx-auto btn" fab text x-large color="primary" @click="goTop">
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>
        </div>

        <div>
          <div class="d-md-flex d-flex mx-auto justify-center py-40">
            <v-btn x-large depressed color="primary" rounded :width="checkWidth" @click="showDialog">
              Sign in
            </v-btn>
            <v-btn x-large class="mx-5" :width="checkWidth" depressed color="primary" rounded outlined tag="a"
              href="mailto:support@nsuk.edu.ng">
              Support
            </v-btn>
          </div>
        </div>

        <div class="py-20">
          <img class="d-flex mx-auto justify-center" src="/img/logo.jpg" width="150" height="auto" />
        </div>

      </div>


    </div>
    </div>


    <!---->
    <div v-else>
      <div class="overlay">
        <div class="mx-6 mx-md-16 mx-sm-16 hero" :style="{ paddingLeft: padLeft }">
          <v-row :justify="isMobile ? 'center' : 'space-between'" class="herocontent">
            <v-col cols="12" md="8" sm="12" :style="{ marginTop: checkmTop }">
              <h3 class="font-weight-bold  text-center text-md-left text-sm-left" style="line-height: 1.2em; font-size: 5rem;">
                Welcome
                to NSUK Leave
                Management System
              </h3>
              <p class="font-weight-medium pFont mt-2 text-center text-md-left text-sm-left"
                style=" line-height: 1.4em; color: white;">
                Review the profiles of 2000+ staff quickly
                and easily.
              </p>
            </v-col>
          </v-row>


          <!------    NON-MOBILE   ------>
          <div class="d-md-flex mt-10 mb-8" v-if="isMedium">
            <v-btn color="primary" :width="smWidth" x-large rounded depressed @click="$router.push('/home/leave')">
              Apply
            </v-btn>

            <v-btn class="ml-md-8 ml-0 support" :width="smWidth" outlined x-large depressed rounded>
              Support
            </v-btn>
          </div>


          <!-- IPAD SCREEN FOR PORTRAIT-->
          <div class="d-md-flex mt-10 mb-8" v-else-if="$vuetify.breakpoint.smOnly">
            <v-btn color="primary" :width="smWidth" x-large rounded depressed @click="showDialog">
              Sign in
            </v-btn>

            <v-btn class="ml-md-8 ml-0 ml-sm-5 support" :width="smWidth" outlined x-large depressed rounded>
              Support
            </v-btn>
          </div>



          <!--------   MOBILE   -------------->
          <div class="d-block d-sm-flex mt-10 mb-8 " v-else>
            <div class="text-center">
              <v-btn x-large width="70%" color="primary" rounded depressed @click="showDialog">
                Sign in
              </v-btn>
            </div>

            <div class="text-center mt-4 mt-sm-0">
              <v-btn class="support" width="70%" outlined x-large depressed rounded>
                Support
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <!--body -->

      <div class="bg_overlay">

        <div class="" style="margin-top: 15rem; width: 100%; padding-inline: 6rem; display: grid; place-items: center; ">
          <div class="card_container d-flex justify-center pt-10 pb-10 px-10"
            style=""
          >
            <v-card style="border: 2px solid #14C38E;  border-radius: 15px;">
              <v-card-text>
                <div class="pt-10 pb-10">
                  <h2 class="text-center" style="color: black;">Automate leave requests</h2>
                  <p class="text-center pt-10" style="color: black;">Automate your leave requests, approvals, and tracking</p>
                </div>

              </v-card-text>
            </v-card>

            <v-card style="border: 2px solid #14C38E; border-radius: 15px;">
              <v-card-text>
                <div class="pt-10 pb-10">
                  <h2 class="text-center" style="color: black;">Less time on paperwork</h2>
                  <p class="text-center pt-10" style="color: black;">Spend less time on paperwork and more time where it matters</p>
                </div>

              </v-card-text>
            </v-card>

            <v-card style="border: 2px solid #14C38E;  border-radius: 15px;">
              <v-card-text>
                <div class="pt-10 pb-10">
                  <h2 class="text-center" style="color: black;">Real-time tracking</h2>
                  <p class="text-center pt-10" style="color: black;">The system simplifies leave applications and provides real-time tracking</p>
                </div>

              </v-card-text>
            </v-card>
          </div>

        </div>


        <div >
          <img
            class="bg-overlay"
            src="../../../assets/employee.svg"
            width="100%"
            height="100%"
            style="margin-top: 6rem; zoom: 1; "
          />

          <!-- absolute body -->
          <div class="absolute_body">
            <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 3rem; ">
              <div>
                <h1 style="font-weight: 600; font-size: 3.5rem;">What Our System Offers</h1>
              </div>

              <div class="d-flex align-center" style="gap: 1rem;">
                <v-btn fab color="primary" depressed>
                  2
                </v-btn>

                <div>
                  <h2>Automated Approvals</h2>
                  <p>
                    Set up automated workflows for faster <br />
                    approvals, reducing delays and <br />
                    administrative overhead.
                  </p>
                </div>

              </div>
            </div>

            <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 3rem; margin-top: 2rem; width: 100%;">

              <div class="d-flex align-center " style="gap: 1rem; align-items: start; width: auto;">
                <v-btn fab color="primary" depressed>
                  1
                </v-btn>
                <div>
                  <h2>Easy Leave Requests</h2>
                  <p>
                    Submit leave requests in seconds from your <br />
                    PC device. Choose from various leave types <br />
                    and attach supporting documents.
                  </p>
                </div>
              </div>


              <div class="d-flex align-center" style="gap: 1rem;">
                <v-btn fab color="primary" depressed>
                  3
                </v-btn>
                <div>
                  <h2>Real-time Tracking</h2>
                  <p>
                    Monitor the status of your leave requests <br />
                    anytime, anywhere. Receive instant <br />
                    notifications about approvals and changes.
                  </p>
              </div>
              </div>

            </div>
          </div>
        </div>


      </div>



      <!-- footer -->
      <div class="d-block" style="margin-top: 15rem;">

        <div>
          <div class="d-md-flex d-flex mx-auto justify-center py-40">
            <v-btn x-large depressed color="primary" rounded :width="checkWidth" @click="showDialog">
              Sign in
            </v-btn>
            <v-btn x-large class="mx-5" :width="checkWidth" depressed color="primary" rounded outlined tag="a"
              href="mailto:support@nsuk.edu.ng">
              Support
            </v-btn>
          </div>
        </div>

        <div class="py-20">
          <img class="d-flex mx-auto justify-center" src="/img/logo.jpg" width="150" height="auto" />
        </div>

      </div>
    </div>



    <login :show="dialogVisible" @dismiss="dialogVisible = false"></login>
      <loading-dialog :show="loading" />
      <message-box :show="messageShow" :message="message" :type="messageType"
        v-on:close-message="messageShow = !messageShow" />
  </div>

</template>


<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import Typography from '@/components/Typography.vue';
import DisplayCard from "@/components/Card.vue";
import Burger from '@/components/Menu/Burger.vue';
import Dialog from '@/components/Dialog.vue';
import { getAllCategoriesApi } from "@/config/api/usercategory";
import ErrorResolver from "@/utils/ErrorResolver";
import MessageBox from "@/components/MessageBox.vue";
import LoadingDialog from '@/components/LoadingDialog.vue';
import ValidationRules from "@/utils/ValidationRules";
import { loginApi } from "@/config/api/login";
import Login from "@/pages/Home/components/Login.vue";


@Component({
  components: {
    Login,
    MessageBox,
    Typography,
    LoadingDialog,
    DisplayCard,
    Burger,
    Dialog
  },


  computed: {
    isMedium: function () {
      return this.$vuetify.breakpoint.mdAndUp;
    },


    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown;
    },


    smBtn: function () {
      if (this.isMedium) {
        this.smWidth = '100%';
      }

      this.smWidth;
    },

    pageHeight: function () {
      return document.body.scrollHeight; //get the page-current height
    },

    adjustImg: function () {
      if (!this.isMedium) {
        return '70px';
      }

      return this.hImg;
    },

    padLeft: function () {
      if (!this.isMedium) {
        return this.pLeft = '0px';
      }

      return this.pLeft;
    },

    checkWidth: function () {
      if (!this.isMedium) {
        return this.width = '40%'
      }


      return this.width
    },

    checkFont: function () {
      if (!this.isMedium) {
        return '40px';
      }

      return '60px';
    },

    checkCard: function () {
      if (!this.isMedium) {
        return this.infoCard = '300';
      }

      return this.infoCard;
    },


    checkmTop: function () {
      if (!this.isMedium) {
        return this.mTop = '200px';
      }

      return this.mTop;
    },
  }
})

export default class HomePage extends Vue {
  public dialogVisible: boolean = false;
  public isValid: boolean = false;
  public rules = ValidationRules;
  public duration: number = 300; //this is static
  public positionButton: string = '0';
  public infoCard: string = '400';
  public mTop: string = '140px';
  public hFont: string = '7.5em';
  public pLeft: string = '60px';
  public subText: string = '3em';
  public btn: string = '20%';
  public hImg: string = '60px';
  public smWidth: string = '20%';
  public text: string = '';
  public step: number = 1;
  public selectedTab = { name: 'Staff Profile'};
  public tabs  = [
    {
      name: 'Staff Profile'
    },

    // {
    //   name: 'Leave Management'
    // }
  ]


  public formData: Record<string, any> = {
    username: '',
    password: ''

  }

  public width: string = '20%';
  public loading: boolean = false;
  public mLeft: string = '10px';
  public items: any = []
  public message: string = '';
  public messageShow: boolean = false;
  public messageType: string = '';

  handleTab(item: any) {
    return this.selectedTab = item;
  }

  goTop() {
    return this.$vuetify.goTo(0, {
      easing: 'easeInCubic',
      duration: 700,
    });
  }

  //@ts-ignore
  gotBottom() {
    //@ts-ignore
    return this.$vuetify.goTo(this.pageHeight, {
      easing: 'easeInCubic',
      duration: 700
    })
  }

  showDialog() {
    this.dialogVisible = true;
  }

  getAll() {
    this.loading = true
    getAllCategoriesApi()
      .then(res => this.items = res.data)
      .catch(err => ErrorResolver.checkErrors(this, err))
      .finally(() => this.loading = false)
  }

  created() {
    this.getAll();
    this.positionButton;
    this.tabs[0];
    this.selectedTab = this.tabs[0]
  }
}
</script>


<style lang="scss" >
.imgHeader {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 96%;
  left: 9%;
  z-index: 999;
}

.header {
  position: absolute;
  top: 0;
  z-index: 999;
  padding-inline: 4rem;
  padding-top: 2rem;
  display: flex;
  width: 100%;
  background-color: white;
  padding-bottom: 1rem;


  .right {
    display: flex;
    margin-left: auto;
    background-color: #F2F2F2;
    font-weight: bold;
    border-radius: 90px;
    padding-inline-start: 0rem;
    font-size: 14px;

    padding-inline-end: 1rem;

    ul {
      display: flex;
      align-items: center;
      gap: 0.6rem;
      list-style: none;

    }

    button {
      padding-block: 0.5rem;
      padding-inline: 1rem;
      border-radius: 90px;

      &.selected {
        background-color: #14C38E;
        color: white;
      }
    }
  }
}

.hero {
  position: absolute;
  top: 0;
  bottom: 0;
  color: #fff;
  padding-top: 4rem;
}

.bg_overlay {
  position: relative;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(358.6deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 50.88%);
  }

  .card_container {
      position: absolute;
      background-color: white;
      gap: 2rem;
      border-radius: 50px;
      box-shadow: 0px 4px 4px 0px #00000040;
    }

  .absolute_body {
    position: absolute;
    bottom: 0;
    margin-bottom: -10rem;
    z-index: 999;
    padding-inline: 8rem;
    width: 100%;
    display: grid;
    place-items: center;
    align-items: center;


  }
}

.hFont {
  z-index: 999;
}

.overlay {
  position: relative;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../../public/img/background.png") no-repeat;
  height: 980px;
  width: 100%;
  background-size: cover;
}

.support {
  border: 2px solid #FFFFFF;
  background: rgba(255, 255, 255, 0.6);
}



@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }


  to {
    transform: translate3d(0, 10px, 0);
  }
}

.btn {
  /* position: absolute; */
  bottom: 0;
  animation: bounce 0.5s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
  animation-iteration-count: infinite;
  animation-name: bounce;
  animation-duration: 0.5;

  /** OTHER BROWSER SUPPORT  e.g IE */
  -webkit-animation-name: bounce;
  -webkit-animation-duration: 0.5;
  -webkit-animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
  -webkit-animation-iteration-count: infinite;
}



@-webkit-keyframes bounce {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 200px, 0);
    transform: translate3d(0, 200px, 0);
  }
}

.image {
  position: absolute;
  top: -89%;
  bottom: 0;
  left: 15%;
  right: 2%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.banner {
  position: fixed;
  top: -40%;
  right: 10%;
  left: 85%;
  width: 15%;
  height: 120%;
  background-image: linear-gradient(to right, #14C38E 79%, #016b4b 79%);
}


.shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.py-40 {
  padding-top: 130px;
}

.py-20 {
  padding-top: 100px;
  padding-bottom: 70px;
}

.__layout__title {
  line-height: 1.4em;
  word-wrap: break-word;
}


/**MOBILE PORTRAIT */

@media only screen and (max-device-width: 600px) and (orientation: portrait) {
  .imgHeader {
    margin-right: auto;
    margin-left: 30%;

  }


  .overlay {
    height: 712px;
    max-height: 800px;
  }

  .hFont {
    font-size: 3.2em;
  }
}


/** MOBILE LANDSCAPE */
@media only screen and (min-device-width: 667px) {
  .hFont {
    font-size: 5em;
  }

  .pFont {
    font-size: 1.8em;
  }
}


/** IPAD PORTRAIT */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .hero {
    margin-top: 2em;
  }


  .hFont {
    font-size: 5em;
  }


  .pFont {
    font-size: 1.6em;
  }


  .btn-block {
    width: 40%;
  }
}


/** IPAD LANDSCAPE */
@media screen and (min-device-width: 768px) and (max-device-width: 1080px) and (orientation:landscape) {
  .btn-block {
    width: 40%;
  }

  .hFont {
    font-size: 60px;
    margin-top: 120px;
  }

  .pFont {
    font-size: 25px;
  }
}


@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
  .hFont {
    font-size: 110px;
  }

  .pFont {
    font-size: 40px;
  }
}
</style>
