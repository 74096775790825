<template >
  <v-app class="homeLayout">
    <v-main >
      <router-view />
    </v-main>

    <p class="basic--text text-center mt-sm-10 mt-5 ml-md-16">&copy; {{ new Date().getFullYear() }} Nasarawa State University, Keffi
    </p>
    <loading-dialog :show="loading" />
    <message-box :show="messageShow" :message="message" :type="messageType"
      v-on:close-message="messageShow = !messageShow" />
  </v-app>
</template>


<script lang="ts">
import Vue from 'vue';
import Guide from "@/components/Guide.vue";
import Component from 'vue-class-component';
import LoadingDialog from '@/components/LoadingDialog.vue';
import MessageBox from '@/components/MessageBox.vue';
import { useAppStore } from '@/store/modules/appStore';
import Burger from '@/components/Menu/Burger.vue';
import Sidebar from '@/components/Menu/Sidebar.vue';
import Dialog from '@/components/Dialog.vue';
import { loginApi } from '@/config/api/login';
import ErrorResolver from '@/utils/ErrorResolver';
import ValidationRules from '@/utils/ValidationRules';
import Storage from '@/hooks/useCache';


@Component({
  components: { Burger, Sidebar, Dialog, LoadingDialog, MessageBox, Guide },

  computed: {
    isMedium: function () {
      return this.$vuetify.breakpoint.mdAndUp;
    },


    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown;
    },



    loginImg: function () {
      if (!this.isMedium) {
        return this.imgLogin = '150';
      }

      return this.imgLogin;
    },

    adjustPad: function () {
      if (!this.isMedium) {
        return this.pHeader = '180px';
      }


      return this.pHeader;
    }
  }
})

export default class Home extends Vue {
  store: any = useAppStore();
  public dialogVisible: boolean = false;
  public loading: boolean = false;
  public rules = ValidationRules;
  isValid: boolean = true;
  public showPassword: boolean = false;
  public message: string = '';
  public messageType: string = '';
  public messageShow: boolean = false;
  public formData: Record<string, any> = {
    username: '',
    password: ''
  };

 
  public pHeader: string = '70px';
  public imgLogin: string = '250';
 

  get isMedium() {
    return this.$vuetify.breakpoint.mdAndUp
  }
}
</script>


<style lang="css">
.homeLayout {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

</style>
